import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MentorPanelComponent } from './mentor-panel.component';
import { SharedModule } from '../website/shared/shared.module';
import { MentorPanelRoutes } from './mentor-panel.routing';
import { MentorSharedModule } from "./mentor-shared/mentor-shared.module";

@NgModule({
    declarations: [MentorPanelComponent],
    imports: [
        CommonModule,
        SharedModule,
        MentorPanelRoutes,
        MentorSharedModule
    ]
})
export class MentorPanelModule { }
