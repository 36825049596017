import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'app-no-data-found',
    templateUrl: './no-data.component.html',
    styleUrls: ['./no-data.component.css']
})
export class NoDataComponent implements OnInit {
    @Input()
    message = 'No data found!!'
    constructor() { }

    ngOnInit() {
    }

}
