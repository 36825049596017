import { Component, OnInit } from '@angular/core';
import { MentorSidebarService } from '../mentor-panel/mentor-shared/mentor-sidebar/mentor-sidebar.service';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-mentor-panel',
  templateUrl: './mentor-panel.component.html',
  styleUrls: ['./mentor-panel.component.scss']
})
export class MentorPanelComponent implements OnInit {

    mentorDetail: any
    lastName: any
    constructor(public sidebarservice: MentorSidebarService, public api: ApiService) {
        // code here
    }

    ngOnInit() {
        this.mentorDetail = this.api.user.mentor.name
    }

    toggleSidebar() {
        this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState())
    }
    toggleBackgroundImage() {
        this.sidebarservice.hasBackgroundImage = !this.sidebarservice.hasBackgroundImage
    }
    getSideBarState() {
        return this.sidebarservice.getSidebarState()
    }

    hideSidebar() {
        this.sidebarservice.setSidebarState(true)
    }
    logOut(): void {
        const check = this.api.logOut()
        if (check) {
            window.location.href = '/login'
        }
    } 

}
