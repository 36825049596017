import { PermissionsGuard } from '../auth/permissions-guard'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { MentorGuard } from '../auth/mentor-guard'
import { MentorPanelComponent } from './mentor-panel.component'
import { NotAuthorizedComponent } from './mentor-shared/not-authorized/not-authorized.component'

const routes: Routes = [
    {
        path: '',
        component: MentorPanelComponent,
        canActivate: [MentorGuard],
        children: [
            {
                path: 'not-authorized',
                component: NotAuthorizedComponent
            },
            {
                path: 'samples',
                data: { permissions: [''] },
                loadChildren: () =>
                    import('../general-modules/documentation/documentation.module').then(
                        (mod) => mod.DocumentationModule
                    )
            }
        ]
    },
    {
        path: '',
        component: MentorPanelComponent,
        canActivate: [MentorGuard],
        children: [
            {
                path: 'dashboard',
                canActivate: [MentorGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./dashboard/dashboard.module').then((mod) => mod.DashboardModule)
            },
            {
                path: 'inspections',
                canActivate: [MentorGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./inspection/inspection.module').then((mod) => mod.InspectionModule)
            },
            {
                path: 'calendar',
                canActivate: [MentorGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./calendar/calendar.module').then((mod) => mod.CalendarModule)
            },
            {
                path: 'not-authorized',
                component: NotAuthorizedComponent
            },
            {
                path: 'change-password',
                canActivate: [MentorGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./change-password/change-password.module').then(
                        (mod) => mod.ChangePasswordModule
                    )
            },
            {
                path: 'update-profile',
                canActivate: [MentorGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./update-profile/update-profile.module').then(
                        (mod) => mod.UpdateProfileModule
                    )
            },
            {
                path: 'transactions',
                canActivate: [MentorGuard],
                data: { permissions: [''] },
                loadChildren: () =>
                    import('./transactions/transactions.module').then(
                        (mod) => mod.TransactionsModule
                    )
            },

        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MentorPanelRoutes { }
